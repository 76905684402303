<div class=".container-fluid text-center">
  <div class="row" style="background-color: #5872b5; padding: 10px;">
    <div class="col-md-2" >
      <img src="/assets/img.png" width="200px" />
    </div>
    <div class="col-md-8" style="color: #eaeaea">
      <h1></h1>
      <h2>Fact Checking for Large Enterprise Knowledge Graphs</h2>
    </div>
    <div class="col-md-2" >
      <app-monitor></app-monitor>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-color: #f8f9fb; padding-bottom: 250px;">

  <div class="row">

<router-outlet></router-outlet>

  </div >

</div>


<footer  class="text-center text-lg-start text-white navbar fixed-bottom" style="background-color: #45526e; ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" style="background: #1b2831; color: #adadae;    padding: 25px 0;">
        <div class="col-md-4">
          Copyright © 2022 <a href="https://dice-research.org/" target=”_blank”>DICE</a>. All rights reserved.
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
      </div>
    </div>

  </div>
</footer>

